import * as React from 'react'
import ComingSoon from '../components/coming-soon/coming-soon'

const AboutUsPage = () => {
    return (
        <ComingSoon></ComingSoon>
    )
  }
export default Object.assign(AboutUsPage, {
      pageTitle: 'About us'
  })